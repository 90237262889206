import moment from 'moment';
import React, { Component, forwardRef } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from "../../../components/Table/index";
import Confirm from '../../../components/Confirm/index';
import FormLabel from '@material-ui/core/FormLabel';
import View from './View';
import env from '../../../environments/index';
import { Send, Edit, Email, Info, MailOutline, ArrowDownward } from '@material-ui/icons';
import {
  CustomInput,
  CustomSelect,
  CustomMultiselect,
  CustomAutocomplete,
  CustomDatePicker,
  CustomSwitch,
  CustomSnackbars
} from '../../../components/Form/index';

import FetchRequest from '../../../services/request';

class FormDialog extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      lists: {},
      row: this.initRow,
      openConfirmReadyToSend: false,
      openConfirmCancelSend: false,
      openConfirmSendNow: false,
      errors: {},
      openSnackbars: {
        status: false,
        variant: "success",
        message: ""
      },
      openDialog: false,
      targetingType: "country",
      rerender: this.rerenderElement(),
      openView: false
    };

    this.emails = [
      "noreply@adsempire.com",
      "advertisers@adsempire.com",
      "marina@adsempire.com",
      "kate.d@adromeda.com",
      "affiliates@advery.com",
      "info@advery.com",
      "help@advery.com",
      "hello@advery.com",
      "admin@cpa-tracker.com",
      "marketing@cpa-tracker.com",
      "bohdan@adsempire.com",
      "asya@adsempire.com",
      "karina@adsempire.com",
      "andrii@adsempire.com",
      "partners@together-ads.com"
    ];

    this.fields = [
      {
        title: 'Id',
        field: 'id',
        sorting: false,
        filtering: false
      },
      {
        title: 'Name',
        field: 'name',
        customSort: (a, b) => a.name - b.name,
      },
      {
        title: 'Date send',
        field: 'dateSend',
        filtering: false
      },
      {
        title: 'Status message',
        field: 'statusMsg',
        sorting: false,
        lookup: {} // Will be loaded from lists later
      },
      {
        title: 'User platform',
        field: 'userPlatform',
        lookup: {} // Will be loaded from lists later
      },
      {
        title: 'CreatedAt',
        field: 'createdAt',
        type: "datetime",
        filtering: false
      },
      {
        field: "updatedBy",
        title: "Updated By",
        filtering: false
      }
    ];
    this.fieldsView = [
      {
        name: "name",
        label: "Name"
      },
      {
        name: "subject",
        label: "Title"
      },
      {
        title: 'User type',
        field: 'userType',
        lookup: {} // Will be loaded from lists later
      },
      {
        name: "targetingType",
        label: "Targeting type"
      },
      {
        name: "userPlatform",
        label: "User platform"
      },
      {
        name: "userGroup",
        label: "User status",
        handlers: {
          hidden: (row) => {
            if (row.targetingType === "country") {
              return true;
            }
          }
        }
      },
      {
        name: "userDeclineReasonId",
        label: "User decline reason",
        handlers: {
          hidden: (row) => {
            if (row.targetingType === "country" && row.userGroup === "Decline") {
              return true;
            }
          },
          userDeclineReasonId: (id) => {
            if (this.state.lists && this.state.lists.declineReasons) {
              const reason = this.state.lists.declineReasons.filter(item => item.id === id);
              return reason.length ? reason[0].title : "-";
            }
          }
        }
      },
      {
        name: "userCountry",
        label: "Country",
        handlers: {
          userCountry: (arr) => {
            if (arr === null || !arr.length || typeof arr !== "object") {
              return "all";
            }
            return (arr.filter(item => item.title).map(item => item.title)).join(", ");
          },
          hidden: (row) => {
            if (row.targetingType === "country") {
              return true;
            }
          }
        }
      },
      {
        name: "userEmails",
        label: "Emails",
        handlers: {
          userEmails: (arr) => {
            if (arr === null || typeof arr !== "object") {
              return [];
            }

            return (arr.map(item => item.title)).join(", ");
          }
        }
      },
      {
        name: "templateId",
        label: "Template",
        handlers: {
          templateId: (id) => {
            if (this.state.lists && this.state.lists.templates) {
              const template = this.state.lists.templates.filter(item => item.id === id);
              return template.length ? template[0].title : "-";
            }
          }
        }
      },
      {
        name: "sender",
        label: "Sender"
      },
      {
        name: "dateSend",
        label: "Date send"
      },
      {
        name: "createdAt",
        label: "Created At"
      },
      {
        name: "updatedAt",
        label: "Updated At"
      },
      {
        name: "createdBy",
        label: "Created By"
      },
      {
        name: "updatedBy",
        label: "Updated By"
      }
    ];
    this._fetchRequest = new FetchRequest();
  }

  get initRow() {
    return {
      dateSend: moment().format(),
      targetingType: "country",
      statusMsg: "New",
      userCountry: [],
    };
  }

  handleClickOpen() {
    this.setState({ openDialog: true });
  }

  handleClose() {
    this.setState({
      openDialog: false,
      targetingType: "country",
      errors: {},
      row: this.initRow
    });
  }

  handleCloseView() {
    this.setState({
      openView: false,
      targetingType: "country",
      errors: {},
      row: this.initRow
    });
  }

  change(name, value) {
    this.state.row[name] = value;
    this.setState({ row: this.state.row });
  }

  changeTypeUser(name, value) {
    this.state.row.userPlatform = value;
    this.state.row.userEmails = [];
    delete this.state.row["templateId"];

    this.setState({ row: this.state.row });

    this.getLists();
  }

  changeUserType(name, value) {
    this.state.row.userType = value;
    this.state.row.userEmails = [];
    delete this.state.row["userPlatform"];
    delete this.state.row["templateId"];

    this.setState({ row: this.state.row });
    this.getLists();
  }

  setEmails() {
    switch (this.state.row.userType) {
      case "advertiser":
        this.state.lists.userEmails = this.state.lists.advertiserEmails;
        break;
      case "affiliate":
        this.state.lists.userEmails = this.state.lists.affiliateEmails;
        break;
      default:
        this.state.lists.userEmails = [];
    }
  }

  checkError(name) {
    return !!this.state.errors[name];
  }

  getErrorMessage(name) {
    if (this.checkError(name)) {
      return this.state.errors[name][0].message;
    }
  }

  setErrorMessage(name, message) {
    this.setState(this.state.errors[name] = [{ message }]);
  }

  cleanErrorMessage(name, message) {
    delete this.state.errors[name]
    this.setState(this.state);
  }

  changeTargetingType(checked) {
    this.state.row["targetingType"] = checked ? "email" : "country";
    this.resetFields();
    this.setState({ targetingType: this.state.row["targetingType"] });
  }

  save() {
    this._fetchRequest.loggin();
    fetch(env.endpoints.post.saveMailingList, {
      method: "post",
      headers: {
        "X-Api-Key": this.props.user.apiKey,
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(this.state.row)
    })
      .then(response => response.json())
      .then(body => {
        if (body.status === 200) {
          this.setState({
            errors: {},
            row: this.initRow,
            targetingType: "country",
            openDialog: false,
            openSnackbars: {
              status: true,
              variant: "success",
              message: "Save success!"
            }
          });
          this.tableRef.current && this.tableRef.current.onQueryChange();
        }
        if (body.status === 500 || body.status === 400) {
          this.setState({ errors: body.errors });
        }
      });
  }

  getLists() {
    this._fetchRequest.loggin();
    fetch(`${env.endpoints.get.getLists}`, {
      method: "get",
      headers: {
        "X-Api-Key": this.props.user.apiKey,
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(body => {
        if (body.status === 200) {
          this.setFiltersList(body.payload.lists);
          this.setState({
            rerender: this.rerenderElement(),
            lists: body.payload.lists
          });
        }
      });
  }

  componentDidMount() {
    this.getLists();
  }

  setFiltersList(lists) {
    this.fields.map(item => {
      if (item.field === 'userPlatform') {
        lists.userPlatforms.map(type => item.lookup[type] = type);
      }
      if (item.field === 'statusMsg') {
        lists.statusMsg.map(type => item.lookup[type] = type);
      }

    });
  }

  resetFields() {
    ["userEmails", "userGroup", "userCountry", "countryByIp"].forEach(item => this.state.row[item] = null);
    this.setState({ row: this.state.row });
  }

  closeNotification() {
    this.setState({
      openSnackbars: {
        status: false,
        variant: "success",
        message: ""
      }
    });
  }

  getData = (query) => {
    this._fetchRequest.loggin();

    if (query) {
      query.page = query.page + 1;
    }

    return new Promise((resolve, reject) => {
      fetch(env.endpoints.post.getMailingLists, {
        method: "post",
        headers: {
          "X-Api-Key": this.props.user.apiKey,
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(query)
      })
        .then(response => response.json())
        .then(body => {
          if (+body.status === 200) {
            resolve(
              {
                data: body.payload,
                page: body.page - 1,
                totalCount: body.count
              }
            );
          }
        });
    });
  };

  readyToSend(row) {
    this._fetchRequest.loggin();
    this.setState({ openConfirmReadyToSend: false });

    if (row.statusMsg === "New") {
      fetch(env.endpoints.post.readyToSend, {
        method: "post",
        headers: {
          "X-Api-Key": this.props.user.apiKey,
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(
          {
            id: row.id,
            statusMsg: "Ready to send"
          }
        )
      })
        .then(response => response.json())
        .then(() => {
          this.setState({
            row: this.initRow,
            openSnackbars: {
              status: true,
              variant: "success",
              message: "Save success!"
            }
          });
          this.tableRef.current && this.tableRef.current.onQueryChange();
        });
    }
  }

  cancelSend(row) {
    this._fetchRequest.loggin();
    this.setState({ openConfirmCancelSend: false });

    if (row.statusMsg === "Ready to send") {
      fetch(env.endpoints.post.cancelSend, {
        method: "post",
        headers: {
          "X-Api-Key": this.props.user.apiKey,
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(
          {
            id: row.id,
            statusMsg: "New"
          }
        )
      })
        .then(response => response.json())
        .then(() => {
          this.setState({
            row: this.initRow,
            openSnackbars: {
              status: true,
              variant: "success",
              message: "Save success!"
            }
          });
          this.tableRef.current && this.tableRef.current.onQueryChange();
        });
    }
  }

  /**
   * @returns {({onClick: onClick, icon: React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>, tooltip: string}|{onClick: onClick, icon: React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>, tooltip: string}|{onClick: onClick, icon: React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>, tooltip: string}|{onClick: onClick, icon: React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>, tooltip: string})[]}
   */
  get tableActions() {
    return [
      {
        icon: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        tooltip: 'Edit',
        onClick: (event, rowData) => {
          if (rowData.statusMsg === "New") {
            this.setState({ row: JSON.parse(JSON.stringify(rowData)) });
            this.handleClickOpen();
          } else {
            this.setState({
              openSnackbars: {
                status: true,
                variant: "warning",
                message: "Status only 'New'"
              }
            });
          }
        }
      },
      {
        icon: forwardRef((props, ref) => <Info {...props} ref={ref} />),
        tooltip: 'Info',
        onClick: (event, rowData) => {
          const row = JSON.parse(JSON.stringify(rowData));

          if (rowData.targetingType === "country") {
            this._fetchRequest
              .postRequest(
                `${env.endpoints.post.viewMailingList}?id=${rowData.id}`,
                this.props.user.apiKey,
                rowData
              )
              .then(body => {
                row.userEmails = body.emails;
                this.setState({
                  openView: true,
                  row
                });
              });
          }

          this.setState({
            openView: true,
            row
          });
        }
      },
      {
        icon: forwardRef((props, ref) => <Email {...props} ref={ref} />),
        tooltip: 'Ready To Send',
        onClick: (event, row) => {
          if (row.statusMsg === "New") {
            this.openConfirmReadyToSend(row);
          } else {
            this.setState({
              openSnackbars: {
                status: true,
                variant: "warning",
                message: "Status only 'New'"
              }
            });
          }
        }
      },
      {
        icon: forwardRef((props, ref) => <MailOutline {...props} ref={ref} />),
        tooltip: 'Change to New',
        onClick: (event, row) => {
          if (row.statusMsg === "Ready to send") {
            this.openConfirmCancelSend(row);
          } else {
            this.setState({
              openSnackbars: {
                status: true,
                variant: "warning",
                message: "Status only 'Ready To Send'"
              }
            });
          }
        }
      },
      {
        icon: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        tooltip: 'Test Send',
        onClick: (event, rowData) => {
          this.openConfirmTestSend(rowData);
        }
      },
      {
        icon: forwardRef((props, ref) => <Send {...props} ref={ref} />),
        tooltip: 'Send Now',
        onClick: (event, row) => {
          if (row.statusMsg !== "Sent") {
            this.openConfirmSendNow(row);
          } else {
            this.setState({
              openSnackbars: {
                status: true,
                variant: "warning",
                message: "Available only for not 'Sent' status!"
              }
            });
          }
        }
      }
    ];
  }

  openConfirmReadyToSend(row) {
    this.setState({
      openConfirmReadyToSend: true,
      row
    });
  }

  closeConfirmReadyToSend() {
    this.setState({
      openConfirmReadyToSend: false,
      row: this.initRow,
      targetingType: "country"
    });
  }

  agreeReadyToSend() {
    this.readyToSend(this.state.row);
  }


  get handlersConfirmReadyToSend() {
    return {
      open: this.openConfirmReadyToSend.bind(this),
      close: this.closeConfirmReadyToSend.bind(this),
      agree: this.agreeReadyToSend.bind(this)
    };
  }

  openConfirmCancelSend(row) {
    this.setState({
      openConfirmCancelSend: true,
      row
    });
  }

  closeConfirmCancelSend() {
    this.setState({
      openConfirmCancelSend: false,
      row: this.initRow,
      targetingType: "country"
    });
  }

  agreeCancelSend() {
    this.cancelSend(this.state.row);
  }


  get handlersConfirmCancelSend() {
    return {
      open: this.openConfirmCancelSend.bind(this),
      close: this.closeConfirmCancelSend.bind(this),
      agree: this.agreeCancelSend.bind(this)
    };
  }

  openConfirmSendNow(row) {
    this.setState({
      openConfirmSendNow: true,
      row
    });
  }

  closeConfirmSendNow() {
    this.setState({
      openConfirmSendNow: false,
      row: this.initRow,
      targetingType: "country"
    });
  }

  agreeSendNow() {
    this.sendNow(this.state.row);
  }


  get handlersConfirmSendNow() {
    return {
      open: this.openConfirmSendNow.bind(this),
      close: this.closeConfirmSendNow.bind(this),
      agree: this.agreeSendNow.bind(this)
    };
  }

  openConfirmTestSend(row) {
    this.setState({
      openConfirmTestSend: true,
      row
    });
  }

  closeConfirmTestSend() {
    this.setState({
      openConfirmTestSend: false,
      row: this.initRow,
      targetingType: "country"
    });
  }

  agreeTestSend() {
    this.testSend(this.state.row);
  }


  get handlersConfirmTestSend() {
    return {
      open: this.openConfirmTestSend.bind(this),
      close: this.closeConfirmTestSend.bind(this),
      agree: this.agreeTestSend.bind(this)
    };
  }

  get handlersTable() {
    return {
      getData: this.getData.bind(this),
    };
  }

  getTemplate(template) {
    template = template && typeof template === "object" ? template.id : template;

    if (this.state.lists.templates && template) {
      return this.state.lists.templates.filter(item => item.id === template)[0];
    }
  }

  getDeclineReason(reason) {
    reason = typeof reason === "object" ? reason.id : reason;

    if (this.state.lists.declineReasons && reason) {
      return this.state.lists.declineReasons.filter(item => item.id === reason)[0];
    }
  }

  checkTargetingType() {
    if (this.state.row.userEmails && this.state.row.userEmails.length) {
      this.state.targetingType = this.state.row.userEmails && this.state.row.userEmails.length ? "email" : "country";
      return this.state.targetingType === "email";
    }

    return this.state.targetingType === "email";
  }

  checkUserStatus() {
    return !this.checkTargetingType() && this.state.row.userGroup === "Decline";
  }

  rerenderElement() {
    return Math.floor(Math.random() * Math.floor(new Date().getTime()));
  }

  sendNow(data) {
    this._fetchRequest.loggin();
    this.setState({ openConfirmSendNow: false });

    if (data.statusMsg !== "Sent") {
      fetch(env.endpoints.post.immediateSend, {
        method: "post",
        headers: {
          "X-Api-Key": this.props.user.apiKey,
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(() => {
          this.setState({
            row: this.initRow,
            openSnackbars: {
              status: true,
              variant: "success",
              message: "Mailing list sent to queue!"
            }
          });
          this.tableRef.current && this.tableRef.current.onQueryChange();
        });
    }
  }

  testSend(data) {
    this._fetchRequest.loggin();
    this.setState({ openConfirmTestSend: false });

    fetch(env.endpoints.post.testSend, {
      method: "post",
      headers: {
        "X-Api-Key": this.props.user.apiKey,
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(() => {
        this.setState({
          row: this.initRow,
          openSnackbars: {
            status: true,
            variant: "success",
            message: "Test email success!"
          }
        });
      });
  }

  get USER_PLATFORM() {
    if (this.state.row.userPlatform && this.state.row.userPlatform.length && this.state.lists.usrTypesByType) {
      return this.state.lists.usrTypesByType.hasOwnProperty(this.state.row.userPlatform)
        ? this.state.lists.usrTypesByType[this.state.row.userPlatform]
        : [];
    }

    return [];
  }

  getTemplates() {
    const userPlatform = this.USER_PLATFORM;

    if (this.state.lists.templates) {
      return this.state.lists.templates.filter(
        item => userPlatform.includes(item.product.toLowerCase()));
    }

    return [];
  }

  getEmails() {
    const userPlatform = this.USER_PLATFORM;
    this.setEmails();

    if (this.state.lists.userEmails) {
      return this.state.lists.userEmails.filter(
        item => userPlatform.includes(item.type));
    }

    return [];
  }

  render() {
    return (
      <div style={{ width: "1200px" }}>
        <CustomSnackbars
          message={this.state.openSnackbars.message}
          variant={this.state.openSnackbars.variant}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          autoHideDuration={6000}
          openSnackbars={this.state.openSnackbars.status}
          handlers={{ closeNotification: this.closeNotification.bind(this) }}
        />
        <Button variant="contained" color="primary" style={{ marginBottom: "15px" }} data-test-id="add_button" onClick={this.handleClickOpen.bind(this)}>
          ADD
        </Button>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          aria-labelledby="form-dialog-title"
          PaperProps={{ "data-test-id": "mailing-list_modal" }}
          open={this.state.openDialog}
          onClose={this.handleClose.bind(this)}
        >
          <DialogTitle id="form-dialog-title">
            {this.state.row.id ? "Edit" : "Add"} Mail list
          </DialogTitle>
          <DialogContent>
            <CustomInput data-test-id="name" label="Name" name="name" value={this.state.row.name} handlers={{ change: this.change.bind(this) }} error={this.checkError("name")} message={this.getErrorMessage("name")} />
            <CustomInput data-test-id="title" label="Title" name="subject" value={this.state.row.subject} handlers={{ change: this.change.bind(this) }} error={this.checkError("subject")} message={this.getErrorMessage("subject")} />
            <CustomSwitch data-test-id="countryTargeting" name="targetingType" handlers={{ changeTargetingType: this.changeTargetingType.bind(this) }} checked={this.checkTargetingType()} disabled={!!this.state.row.id} />
            <CustomSelect data-test-id="userType" label="User type" name="userType" data={this.state.lists.cabinetUsersTypes} value={this.state.row.userType} handlers={{ change: this.changeUserType.bind(this) }} />
            <CustomSelect data-test-id="userPlatform" label="User platform" name="sender" data={this.state.lists.userPlatforms} value={this.state.row.userPlatform} handlers={{ change: this.changeTypeUser.bind(this) }} />
            {this.checkTargetingType() ?
              (
                <CustomMultiselect
                  data-test-id="userEmail"
                  key={this.rerenderElement()}
                  data={this.getEmails()}
                  label="Users email"
                  name="userEmails"
                  value={this.state.row.userEmails}
                  handlers={{
                    change: this.change.bind(this),
                    setErrorMessage: this.setErrorMessage.bind(this),
                    cleanErrorMessage: this.cleanErrorMessage.bind(this)
                  }}
                  error={this.checkError("userEmails")}
                  message={this.getErrorMessage("userEmails")}
                  helperText="Example: example1@text.com, example2@text.com"
                  separators={[",", " "]}
                  showWrongValues={true}
                  ignoreWrongValues={true}
                />
              ) : (
                <div>
                  <CustomSelect data-test-id="userStatus"
                    data={this.state.lists.status || []}
                    label="User status"
                    name="userGroup"
                    value={this.state.row.userGroup}
                    handlers={{ change: this.change.bind(this) }}
                    error={this.checkError("userGroup")}
                    message={this.getErrorMessage("userGroup")} />
                  {this.checkUserStatus() ?
                    (
                      <CustomSelect data-test-id="userDeclineReason"
                        data={this.state.lists.declineReasons || []}
                        label="User decline reason"
                        name="userDeclineReasonId"
                        value={this.state.row.userDeclineReasonId}
                        handlers={{ change: this.change.bind(this) }}
                        error={this.checkError("userDeclineReasonId")}
                        message={this.getErrorMessage("userDeclineReasonId")} />
                    ) : (
                      <span />
                    )
                  }
                  <CustomMultiselect data-test-id="userCountries"
                    data={this.state.lists.countries}
                    label="User country"
                    name="userCountry"
                    value={this.state.row.userCountry}
                    handlers={{ change: this.change.bind(this) }}
                    error={this.checkError("userCountry")}
                    message={this.getErrorMessage("userCountry")} />
                </div>
              )
            }<CustomAutocomplete data-test-id="template" key={this.rerenderElement()} data={this.getTemplates()} label="Template" name="templateId" value={this.getTemplate(this.state.row.templateId)} handlers={{ change: this.change.bind(this) }} error={this.checkError("templateId")} message={this.getErrorMessage("templateId")} />
            <CustomSelect data-test-id="sender" label="Sender" name="sender" data={this.emails} value={this.state.row.sender} handlers={{ change: this.change.bind(this) }} error={this.checkError("sender")} message={this.getErrorMessage("sender")} />
            <CustomDatePicker data-test-id="dateSend" label="Date" format="yyyy/MM/dd" name="dateSend" value={this.state.row.dateSend} handlers={{ change: this.change.bind(this) }} />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose.bind(this)} color="primary" data-test-id="cancel_button">
              Cancel
            </Button>
            <Button color="primary" onClick={this.save.bind(this)} data-test-id="save_button">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Table
          fields={this.fields}
          tableRef={this.tableRef}
          handlers={this.handlersTable}
          actions={this.tableActions}
          data={this.getData}
        />
        <Confirm handlers={this.handlersConfirmReadyToSend} isOpen={this.state.openConfirmReadyToSend} title="Ready to send?" />
        <Confirm handlers={this.handlersConfirmCancelSend} isOpen={this.state.openConfirmCancelSend} title="Change to New?" />
        <Confirm handlers={this.handlersConfirmTestSend} isOpen={this.state.openConfirmTestSend} title="Test send?" />
        <Confirm handlers={this.handlersConfirmSendNow} isOpen={this.state.openConfirmSendNow} title="Really send now?" />
        <View isOpen={this.state.openView} row={this.state.row} fields={this.fieldsView} handlers={{ close: this.handleCloseView.bind(this) }} />
      </div>
    );
  }
}

export default FormDialog;
