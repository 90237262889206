import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(2)
  }
}));

export default function CustomMultiselect(props) {
  const classes = useStyles();

  const change = (event, value) => {
    const wrongValues = getWrongValues(value);
    if (props.showWrongValues && !props.ignoreWrongValues && wrongValues.length){
      setErrorMessage("Wrong values: " + wrongValues.join(", "));
    } else {
      cleanErrorMessage();
    }
    return props.handlers.change(props.name, value);
  };

  const setErrorMessage = (message) => {
    if (props.handlers.setErrorMessage) {
      props.handlers.setErrorMessage(props.name, message);
    }
  };

  const cleanErrorMessage = () => {
    if (props.handlers.cleanErrorMessage) {
      props.handlers.cleanErrorMessage(props.name);
    }
  };

  const findValue = () => {
    if(props.value){
      if (props.showWrongValues) {
        return props.value;
      }
      return props.data.filter(el => (props.value.map(item => item.title.toLowerCase())).includes(el.title.toLowerCase()));
    }
    return [];
  };

  const prepareRecords = (values) => {
    return values
      .map((item) => {
        return { title: item.trim(), type: "" };
      })
      .filter(item => item.title);
  };

  const prepareValues = (event) => {
    if (props.value && props.value.length > 0) {
      return [...props.value, ...prepareRecords(split(event.target.value))];
    } else {
      return [...prepareRecords(split(event.target.value))];
    }
  };

  const split = (value) => {
    let separators = [","]
    if (props.separators) {
      separators = props.separators;
    }
    let values = [value];

    for (let separator of separators) {
      values = values[0].split(separator);
      if (values.length > 1) {
        break;
      }
    }
    return values;
  }

  const onBlur = (event) => {
    const values = prepareValues(event);

    change(event, values);
  };

  const onKeyDown = (event) => {
    if(event.key === "Enter") {
      const values = prepareValues(event);

      change(event, values);
    }
  };

  const getWrongValues = (value) => {
    if (props.data.length && value.length) {
      return value.filter(el => !(props.data.map(item => item.title.toLowerCase())).includes(el.title.toLowerCase()))
          .map(item => item.title);
    }
    return [];
  };

  return (
    <div>
      <FormControl
        fullWidth
        className={classes.formControl}
        error={props.error}
      >
        <Autocomplete
          multiple
          options={props.data}
          getOptionLabel={option => option.title}
          value={findValue()}
          filterSelectedOptions
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              label={props.label}
              onBlur={(event) => onBlur(event)}
              onKeyDown={(event) => onKeyDown(event)}
              helperText={props.helperText}
              fullWidth
            />
          )}
          name={props.name}
          onChange={change}
          data-test-id={props["data-test-id"]}
        />
        <FormHelperText>{props.message}</FormHelperText>
      </FormControl>
    </div>
  );
}
